export const articles = [
    {
        id: 1,
        title: "Wycieczka Węgry-Słowenia-Morawy",
        summary: "Relacja z wycieczki po Węgrzech, Słowenii i Morawach",
        content: `We wrześniu tego roku cztery nasze ekspedientki miały okazję wziąć udział w wycieczce obejmującej Węgry, Słowenię i Morawy, gdzie zgłębiały tajniki wina – od uprawy winorośli, przez zbiory, aż po produkcję.

Węgierska część wycieczki odbyła się w winiarni Lajos Feind, założonej w 1995 roku. Uczestniczki miały możliwość zwiedzenia winnicy, degustacji win oraz spaceru nad malowniczym Balatonem o zachodzie słońca.

Słowenia zachwyciła malowniczymi wzgórzami regionu Goriška Brda, gdzie odwiedziły winiarnię Klet Brda. Program obejmował liczne atrakcje, takie jak zwiedzanie linii produkcyjnej, udział w zbiorach winogron, asystowanie przy produkcji wina, degustacje dopasowane do odpowiednich potraw oraz wycieczkę do Zamku Dobrovo, gdzie również odbyła się degustacja.

Morawy powitały uczestniczki w winiarni Zámecké Vinařství Bzenec, jednej z nielicznych znajdujących się w zamku. Tam, w ponad czterystuletnich piwnicach, odbywały się degustacje lokalnych win.

Wycieczka dostarczyła uczestniczkom wielu pozytywnych wrażeń, nowych informacji oraz rozrywki.`,
        image: "https://gagalon-images.s3.eu-north-1.amazonaws.com/locations/sloneczna.jpg"
    },
];
